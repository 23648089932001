import { explorer } from '@/plugins/axios'
import { unix } from 'core/utils/date'
import * as maths from '@/services/MathsService'
const $experian = process.env.VUE_APP_TYPE === 'experian'

export const getGroups = async form => {
  try {
    const { data } = await explorer.post('/group', form)
    return data
  } catch (err) {
    throw err
  }
}

export const getListings = async ({ form, request, logId }) => {
  try {
    const params = {
      make: request[0].make,
      models: request.map(r => r.model),
      minYear: form.minYear || null,
      maxYear: form.maxYear || null,
      minMileage: form.minMileage || null,
      maxMileage: form.maxMileage || null,
      logId
    }
    const { data } = await explorer.post('/', params)
    return data
  } catch (err) {
    throw err
  }
}

export const indexListings = listings => {
  return listings.map((listing, index) => {
    return { ...listing, originalIndex: index }
  })
}

export const filterMileages = listings => {
  const mileageListings = listings.filter(
    listing => listing.mileage <= 500000 && listing.mileage >= 500
  )
  return mileageListings.length ? mileageListings : null
}

export const mapSeries = (listings, filteredListings) => {
  if (!listings.length) return null

  return listings
    .map(listing => {
      return {
        timestamp: unix(listing.timestamp),
        price: listing.price,
        mileage: listing.mileage,
        model: listing.model,
        originalIndex: listing.originalIndex,
        fillColor: !filteredListings.includes(listing)
          ? '#ffdd57'
          : $experian
          ? '#1D4F91'
          : '#1265FB'
      }
    })
    .sort(function(a, b) {
      return a - b
    })
}

const format = input => {
  return input.toString().toLowerCase()
}

const includeListing = (iterable, item) => {
  return iterable.some(value => value && format(value).includes(format(item)))
}

export const filterListings = (listings, keywords) => {
  return listings.filter(listing => {
    const values = Object.values(listing)
    return keywords.every(keyword => {
      if (keyword[0] === '-') {
        const word = keyword.slice(1)
        return !includeListing(values, word)
      } else if (keyword.includes(',')) {
        const keywords = keyword.split(',')
        return keywords.every(word => includeListing(values, word))
      } else return includeListing(values, keyword)
    })
  })
}

export const removeOutliers = listings => {
  if (listings.length === 1) return listings

  const prices = listings.map(listing => listing.price)
  const mean = maths.getMean(prices)
  const count = listings.length
  const sd = maths.getStandardDeviation(prices, mean, count)
  // lower this to create a tighter group of values on the graph, increase to allow more outliers
  const severity = 1.8

  return maths.filterOutliers(listings, 'price', mean, severity, sd)
}
