export default () => ({
  form: {
    make: '',
    models: [],
    minYear: null,
    maxYear: null,
    minMileage: null,
    maxMileage: null
  },
  groups: null,
  pages: 0,
  request: [],
  listings: null,
  displayOutliers: false,
  keywords: []
})
