export default {
  setForm(state, data) {
    state.form = data
  },
  setGroups(state, data = []) {
    state.groups = data
  },
  setPages(state, data = 0) {
    state.pages = data
  },
  setLogId(state, data) {
    state.logId = data
  },
  selectAll(state) {
    const { length } = state.groups
    state.request = Array.from({ length }, (v, i) => i)
  },
  deselectAll(state) {
    state.request = []
  },
  setRequest(state, data) {
    state.request = data
  },
  setListings(state, data) {
    state.listings = data
  },
  toggleOutliers(state) {
    state.displayOutliers = !state.displayOutliers
  },
  addKeyword(state, data) {
    state.keywords.push(data)
  },
  removeKeyword(state, data) {
    state.keywords.splice(data, 1)
  },
  clearKeywords(state) {
    state.keywords = []
  },
  reset: (state, data) => Object.assign(state, data)
}
