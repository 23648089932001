import * as explorer from '../services'
import state from './state'
import { pickBy } from 'lodash'

export default {
  async getGroups({ commit, state }, form) {
    const req = pickBy(form, v => v)
    try {
      commit('setListings', null)
      commit('setGroups', null)
      commit('setForm', form)
      const { groups, pages, logId } = await explorer.getGroups(req)
      commit('setGroups', groups)
      commit('selectAll')
      commit('setPages', pages)
      commit('setLogId', logId)
      if (state.displayOutliers) commit('toggleOutliers')
      if (state.keywords.length) commit('clearKeywords')
    } catch (err) {
      throw err
    }
  },
  async getListings({ commit, state }, params) {
    try {
      params.logId = state.logId
      params.groups = state.groups
      const { listings } = await explorer.getListings(params)
      commit('setListings', listings)
    } catch (err) {
      commit('setListings', [])
      throw err
    }
  },
  reset({ commit }) {
    commit('reset', state())
  }
}
