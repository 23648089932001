import * as explorer from '../services'

export default {
  form: state => state.form,
  make: state => state.form.make,
  models: state => state.form.models,
  hasSearched: (state, { make, models, hasGroups }) =>
    hasGroups || (make.length && models.length),
  groups: state => state.groups,
  logId: state => state.logId,
  hasGroups: state => state.groups && state.groups.length,
  request: state => state.request,
  requestedGroups: ({ groups, request }) =>
    groups ? groups.filter((group, index) => request.includes(index)) : [],
  count: ({ listings }) => (listings ? listings.length : 0),
  indexedListings: ({ listings }) =>
    listings ? explorer.indexListings(listings) : null,
  keywords: state => state.keywords,
  isFiltering: state => state.keywords.length > 0,
  filteredListings: ({ keywords }, { indexedListings }) =>
    indexedListings ? explorer.filterListings(indexedListings, keywords) : null,
  dataset: (state, { isFiltering, filteredListings, indexedListings }) =>
    isFiltering ? filteredListings : indexedListings,
  outlierFreeListings: (state, { dataset }) =>
    dataset ? explorer.removeOutliers(dataset) : null,
  series: (state, getters) => {
    const { listings, displayOutliers } = state
    const { outlierFreeListings, dataset } = getters
    const dataset2 = displayOutliers ? dataset : outlierFreeListings
    return listings ? explorer.mapSeries(dataset2, outlierFreeListings) : null
  },
  mileageSeries: (state, { series }) =>
    series ? explorer.filterMileages(series) : null
}
