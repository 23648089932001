<template>
  <div>
    <ExplorerSearch />
    <div v-if="count">
      <ExplorerMakeModelBanner />
      <section class="section">
        <div class="container">
          <SectionHeader
            title="Data visualisation"
            title-icon="fad fa-chart-bar"
          />
          <ExplorerGraphs />
        </div>
      </section>
      <section class="section">
        <div class="container">
          <SectionHeader
            :title="`Historical adverts (${countInfo})`"
            title-icon="fal fa-list"
          />
          <div class="columns is-multiline">
            <div class="column is-12">
              <ExplorerFilters />
            </div>
            <div class="column is-12">
              <ExplorerListings />
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import store from '../stores'
import registerStore from '@/store/services'
import { mapGetters } from 'vuex'
export default {
  name: 'Explorer',
  components: {
    SectionHeader: () =>
      import('core/modules/section-header/components/SectionHeader'),
    ExplorerListings: () => import('../components/ExplorerListings'),
    ExplorerGraphs: () => import('../components/ExplorerGraphs'),
    ExplorerFilters: () => import('../components/ExplorerFilters'),
    ExplorerSearch: () => import('../components/ExplorerSearch'),
    ExplorerMakeModelBanner: () =>
      import('../components/ExplorerMakeModelBanner')
  },
  created() {
    registerStore('explorer', store)
  },
  destroyed() {
    this.$store.dispatch('explorer/reset')
  },
  computed: {
    ...mapGetters('explorer', [
      'count',
      'filteredListings',
      'isFiltering',
      'requestedGroups'
    ]),
    groupListingCount() {
      const counts = this.requestedGroups.map(group => group.count)
      return counts.reduce((acc, val) => acc + val)
    },
    countInfo() {
      const { count, isFiltering, filteredListings, groupListingCount } = this
      const maxListings = groupListingCount > 2500 && count === 2500
      const addon = maxListings ? ` of ${groupListingCount} total listings` : ''
      if (count && isFiltering)
        return `${filteredListings.length}/${count}${addon}`
      else if (count) return `${count}${addon}`
      else return 0
    }
  }
}
</script>
